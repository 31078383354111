/* eslint-disable */
import * as React from 'react'

import { Button } from '@components/React/MDC'

import { closeButton, Backdrop, Container, Content, ModalFooter, ModalHeader, ModalTitle } from './Modal.Styled'

interface ModalProps {
  backdropColor?: boolean,
  children?: React.ReactNode,
  className?: string,
  closeButton?: boolean,
  disableBackdropClick?: boolean,
  footer?: React.ReactNode,
  fullScreenMedium?: boolean,
  grayish?: boolean,
  notFullScreen?: boolean
  header?: React.ReactNode,
  onClose?: () => void,
  show?: boolean,
  sizeClose?: number,
  title?: React.ReactNode,
}

export class Modal extends React.Component<ModalProps> {

	private static defaultProps = {
		children: null,
		closeButton: true,
		onClose: () => void 0,
		show: false,
		title: '',
	}

	private backdrop: HTMLDivElement
	private container: HTMLDivElement

	public render() {
		const { props } = this

		if (!props.show) {
			return null
		}

		const containerProps: { [key: string]: any } = {
			innerRef: (element) => { this.container = element },
		}

		if (props.className) {
			containerProps.className = props.className
		}

		return (
			<Backdrop backdropColor={ props.backdropColor }
				onClick={ props.disableBackdropClick ? null : this.handleBackdropClick }
				innerRef={ (element) => { this.backdrop = element } }>
				<Container { ...containerProps } notFullScreen={ props.notFullScreen } grayish={ props.grayish }
					fullScreenMedium={ props.fullScreenMedium }>
					{ props.closeButton && (
						<Button className={ closeButton(props.sizeClose) } color="black" onClick={ props.onClose } rounded
							iconData={ { type: 'times', weight: 'light' } }/>
					) }
					{ props.title && <ModalTitle>{ props.title }</ModalTitle> }
					{ props.header && <ModalHeader>{ props.header }</ModalHeader> }
					<Content>{ props.children }</Content>
					{ props.footer && <ModalFooter>{ props.footer }</ModalFooter> }
				</Container>
			</Backdrop>
		)
	}

	public shouldComponentUpdate(nextProps: Readonly<ModalProps>) {
		return this.props !== nextProps
	}

	private handleBackdropClick = (event) => {
		if (event.target === this.backdrop) {
			this.props.onClose()
		}
	}
}
