import { createTheme } from '@mui/material/styles'
import { Alpha, Black, Blue, Gray, Green, Orange, Red, White } from '@styles/Color'
import { plPL } from '@mui/material/locale'

declare module '@mui/material/Button' {
	interface ButtonPropsVariantOverrides {
		square: true;
		squareContained: true;
		squareOutlined: true;
		squareDanger: true;
		squareContainedDanger: true;
	}
}

export const mainTheme = createTheme({
	palette: {
		primary: {
			main: Blue[800],
			contrastText: White,
		},
		secondary: {
			main: Gray[600],
		},
		warning: {
			main: Orange[500],
			contrastText: White,
		},
		info: {
			main: Blue[500],
		},
		success: {
			main: Green[400],
		},
		error: {
			main: Red[500],
		},
	},
	typography: {
		h2: {
			fontSize: 12,
			fontWeight: 600,
		},
		body1: {
			fontSize: 14,
		},
		button: {
			fontSize: 14,
			fontWeight: 600,
		},
	},
	components: {
		MuiInputBase: {
			styleOverrides: {
				root: {
					fontSize: 16,

					'.MuiOutlinedInput-notchedOutline': {
						'> legend': {
							fontSize: '0.65em',
						},
					},
				}
			}
		},
		MuiTooltip: {
			styleOverrides: {
				tooltip: {
					background: Gray[200],
					borderRadius: 6,
					boxShadow: '0 3px 6px ' + Alpha(Black, 0.3),
					color: Gray[900] + '!important',
					fontSize: 12,
					maxWidth: 235,
					padding: 8,
					textAlign: 'left',
					width: '100vw',
				},
			},
		},
		MuiButton: {
			styleOverrides: {
				text: {
					':hover': {
						backgroundColor: Blue[50],
					},
				},
				outlined: {
					borderRadius: 25,
					padding: '12px 15px',

					':hover': {
						backgroundColor: Blue[50],
					},
				},
				contained: {
					borderRadius: 25,
					padding: '12px 15px',

					':hover': {
						color: White,
					},
				},
			},
			variants: [
				{
					props: { variant: 'square' },
					style: {
						color: Blue[800],
						fontWeight: 600,
						padding: '8px 12px',

						'&:hover': {
							color: Blue[900],
						},

						'&:disabled': {
							color: Blue[800],
							opacity: .6
						},
					},
				}, {
					props: { variant: 'squareContained' },
					style: {
						backgroundColor: Blue[800],
						color: White,
						padding: '8px 12px',

						'&:hover': {
							color: White,
							backgroundColor: Blue[900],
						},

						'&:disabled': {
							color: White,
						},
					},
				}, {
					props: { variant: 'squareOutlined' },
					style: {
						backgroundColor: White,
						border: '1px solid ' + Blue[800],
						color: Blue[800],
						padding: '8px 12px',

						'&:hover': {
							backgroundColor: Gray[100],
							borderColor: Blue[900],
							color: Blue[900],
						},

						'&:disabled': {
							borderColor: Gray[100],
						},
					},
				}, {
					props: { variant: 'squareDanger' },
					style: {
						color: Red[800],
						fontWeight: 400,
						padding: '8px 12px',

						'&:hover': {
							color: Red[900],
						},
					},
				}, {
					props: { variant: 'squareContainedDanger' },
					style: {
						backgroundColor: Red[800],
						color: White,
						fontWeight: 400,
						padding: '8px 12px',

						'&:hover': {
							color: White,
							backgroundColor: Red[900],
						},
					},
				},
			],
		},
		MuiIconButton: {
			styleOverrides: {
				colorPrimary: {
					backgroundColor: Blue[800],
					color: White,
					fontSize: 24,
					padding: 13,

					'&:hover': {
						color: White,
						backgroundColor: Blue[900],
					},
				}
			},

		},
		MuiMenuItem: {
			styleOverrides: {
				root: {
					'&.Mui-selected': {
						backgroundColor: Blue[50],

						'&:hover': {
							backgroundColor: Blue[100],
						},
					},
				},
			},
		},
		MuiAutocomplete: {
			styleOverrides: {
				tag: {
					borderColor: Blue[800],
					color: Gray[800],

					'.MuiSvgIcon-root': {
						color: Blue[800],

						'&:hover': {
							color: Blue[900],
						},
					},
				},
			},
		},
		MuiList: {
			styleOverrides: {
				root: {
					maxHeight: 320,
				},
			},
		},
		MuiChip: {
			styleOverrides: {
				clickableColorPrimary: {
					'&:hover': {
						backgroundColor: Blue[800],
					}
				}
			}
		},
	},
}, plPL)

export const roundTheme = createTheme({
	palette: {
		primary: {
			main: Blue[800],
			contrastText: White,
		},
		secondary: {
			main: Gray[600],
		},
		warning: {
			main: Orange[500],
			contrastText: White,
		},
		info: {
			main: Blue[500],
		},
		success: {
			main: Green[400],
		},
		error: {
			main: Red[500],
		},
	},
	typography: {
		fontSize: 16,
		h2: {
			fontSize: 12,
			fontWeight: 400,
		},
		body1: {
			fontSize: 14,
		},
		button: {
			fontSize: 14,
			fontWeight: 600,
		},
	},
	shape: {
		borderRadius: 25,
	},
})
